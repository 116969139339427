import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><em parentName="p">{`Notifications`}</em>{` are messages that communicate information to the user.`}</p>
    <h2 {...{
      "id": "format"
    }}>{`Format`}</h2>
    <h4 {...{
      "id": "title"
    }}>{`Title`}</h4>
    <p>{`All notifications have subject titles, which should be short and descriptive. (Example: “Tester-app-02 has crashed.”)`}</p>
    <h4 {...{
      "id": "message"
    }}>{`Message`}</h4>
    <p>{`We recommend the body of the notification be contained within two lines. Be descriptive and include any troubleshooting actions or next steps. When possible, communicate the main message using just the title. You can include `}<a parentName="p" {...{
        "href": "/components/link"
      }}>{`links`}</a>{` within the notification body that redirect the user to next steps.`}</p>
    <h4 {...{
      "id": "dismissal"
    }}>{`Dismissal`}</h4>
    <p>{`We recommend that toast notifications automatically disappear after five seconds. Inline notifications are persistent until the user dismisses them. All notifications have at least one method of dismissal (typically, it is a small “x” in the upper right hand corner).`}</p>
    <h4 {...{
      "id": "icons"
    }}>{`Icons`}</h4>
    <p>{`Icons may provide additional clarity. Icons should be placed to the left of a title. These glyphs (16x16) can be found in the `}<a parentName="p" {...{
        "href": "/guidelines/iconography/library"
      }}>{`iconography`}</a>{` library.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "36.141304347826086%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "The three icon states",
        "title": "The three icon states",
        "src": "/static/fa136b8d4258af4934d111fe4279565a/fb070/notification-usage-1.png",
        "srcSet": ["/static/fa136b8d4258af4934d111fe4279565a/d6747/notification-usage-1.png 288w", "/static/fa136b8d4258af4934d111fe4279565a/09548/notification-usage-1.png 576w", "/static/fa136b8d4258af4934d111fe4279565a/fb070/notification-usage-1.png 1152w", "/static/fa136b8d4258af4934d111fe4279565a/c3e47/notification-usage-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Notification type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Purpose`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Toast notification`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Toasts are a non-modal, time-based window elements used to display short messages; they usually appear at the bottom of the screen and disappear after a few seconds.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Inline notification`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Inline notifications show up in task flows, to notify users of the status of an action. They usually appear at the top of the primary content area.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "placement"
    }}>{`Placement`}</h2>
    <h4 {...{
      "id": "toast-notifications"
    }}>{`Toast notifications`}</h4>
    <p>{`Toast notifications slide in and out a page from the top-right corner. Actionable notifications do not appear on mobile screen widths.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "48.233695652173914%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Toast notification example",
        "title": "Toast notification example",
        "src": "/static/fdd85ccb991c9119f2ef4283b690b5cf/fb070/notification-usage-2.png",
        "srcSet": ["/static/fdd85ccb991c9119f2ef4283b690b5cf/d6747/notification-usage-2.png 288w", "/static/fdd85ccb991c9119f2ef4283b690b5cf/09548/notification-usage-2.png 576w", "/static/fdd85ccb991c9119f2ef4283b690b5cf/fb070/notification-usage-2.png 1152w", "/static/fdd85ccb991c9119f2ef4283b690b5cf/c3e47/notification-usage-2.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h4 {...{
      "id": "inline-notifications"
    }}>{`Inline notifications`}</h4>
    <p>{`Inline notifications appear near its related item. In `}<a parentName="p" {...{
        "href": "/components/form"
      }}>{`forms`}</a>{`, we recommend placing the inline notification at the bottom of the form, right before the submission buttons. Depending on the context of the page, inline notifications can appear above the content as well.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "113.45108695652175%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Form example with inline notification",
        "title": "Form example with inline notification",
        "src": "/static/cda1826e4cf1266ab805e62922c3bced/fb070/notification-usage-3.png",
        "srcSet": ["/static/cda1826e4cf1266ab805e62922c3bced/d6747/notification-usage-3.png 288w", "/static/cda1826e4cf1266ab805e62922c3bced/09548/notification-usage-3.png 576w", "/static/cda1826e4cf1266ab805e62922c3bced/fb070/notification-usage-3.png 1152w", "/static/cda1826e4cf1266ab805e62922c3bced/c3e47/notification-usage-3.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      